export default function Websites({ websites, status }) {

  const isDown = websites?.filter((monitor) => monitor?.status === 9 || monitor?.status === 8)

  const isUp = websites?.filter((monitor) => monitor?.status === 2)

  return (
    <section className="py-10">
      <div className="container mx-auto lg:max-w-[1000px]">
        <div className="shadow-bar px-10 py-9">
          {status === 'fetching' ? (
            <div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]" role="status">
              <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">Loading...</span>
            </div>
          ) : (
            <>
              {isDown.length > 0 && (
                isDown?.map((item: any, index: number) => (
                  <div className="mb-3 pb-3 border-b border-b-[#e6e7e8] !text-red-700 flex justify-between" key={item.id}>
                    <span>{item.friendly_name}</span>
                    <div className="flex items-center">
                      <div className="w-3 h-3 rounded-full mr-2 bg-red-600 animate-pulse" />
                      <span className="text-red-600">Down</span>
                    </div>
                  </div>
                ))
              )}

              {isUp?.map((item: any, index: number) => (
                <div key={item.id} className="mb-3 pb-3 border-b border-b-[#e6e7e8] flex justify-between">
                  <span>{item.friendly_name}</span>
                  <div className="flex items-center">
                    <div className="w-3 h-3 rounded-full mr-2 bg-green-600 animate-pulse" />
                    <span className="text-green-600">Up</span>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </section>
  )
}