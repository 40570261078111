export default function Bar({ websites, status }) {

  const isDown = websites?.filter((monitor) => monitor?.status === 9 || monitor?.status === 8)

  const isUp = websites?.filter((monitor) => monitor?.status === 2)


  return (
    <section className="-mt-7">
      <div className="container lg:max-w-[1000px] mx-auto bg-white shadow-bar p-9 rounded-md">
        {status === 'fetching' ? (
          <div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]" role="status">
            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">Loading...</span>
          </div>
        ) : (
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <div className={`w-8 h-8 rounded-full mr-3 animate-pulse ${isDown.length > 0 ? 'bg-red-600' : 'bg-green-600'}`} />
              {isDown?.length > 0 ? (
                <h2 className="text-red-600 lg:text-3xl">Some systems down</h2>
              ) : (
                <h2 className="text-green-600 lg:text-3xl">All systems operational</h2>
              )}
            </div>
            <div className="flex items-center">
              <p className="text-base text-ubo-taillow font-medium">Total: {websites.length}</p>
              <p className="mx-3 text-base text-red-600 font-medium">Down: {isDown.length}</p>
              <p className="text-base text-green-600 font-medium">Up: {isUp.length}</p>
            </div>
          </div>
        )}
      </div>
    </section>
  )
}