/* eslint-disable @typescript-eslint/no-unused-expressions */
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import Header from './components/Header';
import Websites from './components/Websites';
import Bar from './components/Bar';

const API_KEY = 'ur1167932-2ed589edf45153e639c5340f';
const API_URL = 'https://api.uptimerobot.com/v2/getMonitors';
const BATCH_SIZE = 50;

function MyComponent() {
  const [items, setItems] = useState([]);
  const [status, setStatus] = useState('fetching');

  useEffect(() => {
    fetchData();
    setInterval(() => { fetchData() }, 60000);
  }, []);

  const fetchData = async () => {

    try {
      let allItems = [];
      let currentPage = 1;
      let totalPages = 1;

      while (currentPage <= totalPages) {
        const response = await axios.post(
          API_URL,
          {
            api_key: API_KEY,
            offset: (currentPage - 1) * BATCH_SIZE,
            limit: BATCH_SIZE,
          },
        );

        const { monitors, pagination } = response.data; // Adjust the property paths based on the UptimeRobot API response structure
        allItems = [...allItems, ...monitors];
        totalPages = Math.ceil(pagination.total / BATCH_SIZE);
        currentPage++;
      }

      setItems(allItems);
      setStatus(null);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };



  return (
    <main className="relative min-h-screen max-w-[2200px] mx-auto overflow-x-hidden">
      <Header />
      <Bar websites={items} status={status} />
      <Websites websites={items} status={status} />
    </main>
  );
}

export default MyComponent;
